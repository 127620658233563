// eslint-disable-next-line import/no-extraneous-dependencies
import { IPC_MESSAGES } from '@/native/auth/auth.constants';

import ApplicationState from '@/singletons/application.state.singleton';

let signInAzureElectron = async () => { console.log('not initated electron'); };
if (ApplicationState.platform === 'electron') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('electron').then(({ ipcRenderer }) => {
    signInAzureElectron = async () => {
      console.log('Electron', ipcRenderer);
      ipcRenderer.send(IPC_MESSAGES.LOGIN);
      localStorage.auth_method = 'azure';
    };
  });
}

const signInAzureWeb = async (msal) => {
  if (msal.isAuthenticated) {
    console.log('signing in web - redirect');
    await window.msal.loginRedirect({
      scopes: ['user.read'],
    });
  } else {
    console.log('signing in web - signin');
    msal.signIn();
  }
};

class LoginController {
  // eslint-disable-next-line class-methods-use-this
  async signInAzure(msal) {
    if (ApplicationState.platform === 'electron') {
      console.log('signing in electron');
      await signInAzureElectron();
    } else {
      console.log('signing in web');
      await signInAzureWeb(msal);
    }
  }
}

export default new LoginController();
