<template>
  <div class="form--input__container lg form--margin">
    <label class="form__label">{{ label }}</label>
    <input
      v-model="inputVal"
      class="form__input"
      autocomplete="username"
      :required="required"
      :type="type"
      name="email"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/require-prop-types */
export default {

  props: {

    aria: {
      type: String,
      required: false,
    },

    label: {
      required: true,
      type: String,
    },

    value: {
      required: true,
    },

    type: {
      required: false,
      default: 'text',
    },

    placeholder: {
      required: false,
    },

    required: {
      required: false,
      type: Boolean,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};

</script>
