<template>
  <div class="page page-full flex flex-center">
    <form
      class="form form--simple flex flex-center flex-column"
      @submit.prevent="signInBasic"
    >
      <Logo
        logo-type="secondary"
        aria="Login logo"
        width="400px"
        height="300px"
      />
      <p v-if="error">
        {{ error }}
      </p>

      <!-- Third party sign in -->
      <template v-if="typeof $route.params.section === 'undefined'">
        <h6 class="form--header margin margin-medium margin-top">
          {{ $t('Views.Login.sign_in_with') }}
        </h6>

        <Button
          aria="Single Sign On Office365"
          variant="primary"
          class="margin margin-medium margin-bottom margin-top form-input"
          @click="signInAzure"
        >
          <svg-icon
            src="../icons/office-sso.svg"
            class="white"
          />
          {{ $t('Views.Login.office365') }}
        </Button>
      </template>

      <!-- Credentials sign in -->
      <template v-if="$route.params.section === 'basic'">
        <InputGroup
          class="margin margin-medium margin-top"
          aria="Input Email"
          label="Email"
          type="email"
          :required="true"
          :value="email"
          :placeholder="$t('Views.Login.email')"
          @input="email = $event"
        />

        <InputGroup
          aria="Input Password"
          label="Password"
          type="password"
          class="margin margin-medium margin-bottom"
          :required="true"
          :value="password"
          :placeholder="$t('Views.Login.password')"
          @input="password = $event"
        />

        <InputContainer>
          <Button
            v-if="isLoading"
            aria="Loading indicator"
            class="margin margin-medium margin-bottom margin-top form-input"
          >
            {{ $t('Views.Login.loading') }}
          </Button>

          <Button
            v-if="!isLoading"
            aria="Sign in with basic, submit button"
            type="submit"
            variant="primary"
            class="margin margin-medium margin-bottom margin-top form-input"
          >
            {{ $t('Views.Login.sign_in') }}
          </Button>
        </InputContainer>
      </template>

      <!-- Login Method Switcher-->
      <InputContainer
        v-if="typeof $route.params.section === 'undefined'"
      >
        <h6 class="form__label label--centered">
          {{ $t('Views.Login.or') }}
        </h6>
        <ExtendedRouterLink
          :to="'/login/basic'"
          class="hyperlink margin margin-medium margin-bottom margin-top"
        >
          <h6 class="hyperlink">
            {{ $t('Views.Login.use_credentials') }}
          </h6>
        </ExtendedRouterLink>
      </InputContainer>

      <InputContainer v-if="$route.params.section === 'basic'">
        <Button
          variant="primary-reverse"
          class="margin margin-medium margin-bottom margin-top form-input"
          @click="useSingleSignOn"
        >
          <svg-icon
            src="/icons/office-sso.svg"
            class="green"
          />
          {{ $t('Views.Login.office365') }}
        </Button>
      </InputContainer>
    </form>
  </div>
</template>

<script>
/** State */
// eslint-disable-next-line import/no-extraneous-dependencies
import ApplicationState, { STATE_VERIFY_SESSION } from '@/singletons/application.state.singleton';
/** Components */
import Logo from '@/components/logo/logo.component.vue';
import InputGroup from '@/components/input/input.group.component.vue';
import InputContainer from '@/components/input/input.container.component.vue';
// import Button from '@/components/button/button.component.vue';
import Button from '@/components/button/button.vue';
import ExtendedRouterLink from '@/components/router/router.link.component.vue';
import CompanyState from '@/singletons/company.state.singleton';
/** Service */
import AuthService from '@/Services/Auth/auth.service';
import LoginController from './login.controller';

export default {
  components: {
    Logo,
    InputGroup,
    InputContainer,
    Button,
    ExtendedRouterLink,
  },
  data() {
    return {
      ApplicationState,
      singleSignOn: true,
      email: '',
      password: '',
      error: null,
      interval: null,
    };
  },
  computed: {
    isLoading() { return this.ApplicationState.appState === STATE_VERIFY_SESSION; },
  },
  methods: {
    /** Method switching */
    useSingleSignOn(e) {
      console.log(e);
      e.preventDefault();
      this.$router.push('/login');
    },
    useBasic(e) {
      e.preventDefault();
      try {
        this.$router.push('/login/basic');
      } catch (error) {
        console.log('Error routing to login/basic', error);
      }
    },
    async signInAzure(e) {
      LoginController.signInAzure(this.$msal);
      this.$router.push({ name: CompanyState.fallbackRouteName });
      e.preventDefault();
    },
    async signInBasic(e) {
      e.preventDefault();
      if (!this.email.includes('@')) {
        this.error = 'Please enter a valid email.';
        return;
      }
      const { email, password } = this;
      const emailLower = email.toLowerCase();
      try {
        await AuthService.loginBasic({ email: emailLower, password });
        this.$router.push({ name: CompanyState.fallbackRouteName });
      } catch (err) {
        this.handleSignInError(err);
      }
    },
    handleSignInError({ response }) {
      const data = response?.data;
      this.error = data?.message?.Message || data?.message || 'Hit an error';
      AuthService.logout('login:view:error');
    },
  },
};
</script>
<style lang="scss" scoped>
.hyperlink{
  color: var(--primary-color);
}
.form-input{
  min-width: 320px !important;
}
.icon {
  &.green {
    fill: var(--primary-color);
  }
  &.white {
    fill: white;
  }
}
</style>
